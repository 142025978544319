import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { IoMdCheckboxOutline } from "react-icons/io"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import LeadForm from "../components/form"
import Contactform from "../components/contact"

const NewMoverPage = () => (
  <Layout>
    <Seo title="New Move Checklist for Medicare" />

    <main className="mx-auto mt-5">
      <div className="grid grid-cols-none items-center">
        {/* <div className="w-full overflow-hidden"> */}
        <div className="">
          <StaticImage
            src="../images/Movingday_XL.jpg"
            as="div"
            // className="sm:absolute overflow-hidden left-0 -z-10 md:h-48 sm:h-52 w-full"
            className="sm:relative -z-10 sm:h-52 md:h-48 w-full overflow-hidden sm:brightness-125"
            //imgClassName=""
            loading="lazy"
            layout="fullWidth"
            apectRatio={16 / 9}
            //width={300}
            //quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
            //style={{ marginBottom: `1.45rem` }}
          />
        </div>
        {/* <div className="text-center mx-auto bg-orange-100/40 py-4 sm:p-4 md:p-4 sm:relative z-10 sm:max-w-screen-lg md:h-48 sm:h-52sm:backdrop-brightness-150 sm:backdrop-blur-sm"> */}
        <div className="place-items-center text-center mx-auto sm:absolute left-0 right-0 bg-orange-100/40 py-4 sm:p-4 sm:h-52 md:h-48 z-10 sm:max-w-screen-lg">
          <h1 className="mb-4 text-4xl text-gray-900 tracking-tight leading-8 font-extrabold">
            Welcome to the neighborhood!
          </h1>
          <p className="text-2xl sm:text-slate-900 text-indigo-800 font-bold">
            A new move comes with lots of emotions and lots of things to do.
            We'll break down what you need to know about Medicare and your move
            and how to make sure you can access healthcare in your new location.
          </p>
        </div>
      </div>
      <div className="p-4 mx-auto mt-5 max-w-screen-lg">
        {/* Medicare Supplement Explanation */}
        <div className="px-20 py-4 space-y-4">
          <h2 className="p-2 text-2xl text-slate-800 font-bold leading-8 tracking-tight text-center">
            You moved and have a Medicare Supplement
          </h2>
          <p>
            Medicare Supplements work nationwide so you can still go to any
            doctor who takes Medicare without worrying about networks or if your
            Supplement will cover your Medicare deductibles, copays and
            coinsurance. Your Medicare Supplement will work exactly the same as
            before you moved. You just have to give your new Doctor your
            Supplement information. If you have a Part D Prescription Drug Plan
            and moved outside the service are, you will need to choose a
            Prescription Drug Plan that is available in your new home service
            area.
          </p>
          <p className="text-slate-700 font-semibold tracking-tight leading-8 italic">
            But... Your premium may change -{" "}
            <span className="text-red-800">sometimes drastically</span>
          </p>
          <p>
            Whether your premium changes when you move is dependent on the
            company's policies. The only way to find out how much your
            Supplement will cost is to call the company and ask or dig through
            the policy documents.
          </p>
          <p>
            The good news is that this is the perfect time to call a friendly,
            independent insurance agent and get a quote for a policy at your new
            location. There are likely different options available that can save
            you a lot of money.
          </p>
          <div className="mt-5 flex justify-center">
            <Link
              to="/gethelp"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium shadow rounded-md text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
            >
              Get Medicare Supplement Quote
            </Link>
          </div>
        </div>

        {/* Medicare Advantage Explanation */}
        <div className="px-20 py-4 space-y-4">
          <h2 className="p-2 text-2xl text-slate-800 font-bold leading-8 tracking-tight text-center">
            You moved and have a Medicare Advantage Plan
          </h2>
          <p>
            Medicare Advantage plans are based on your location. So if you move
            outside the service area of your current plan, you{" "}
            <span className="underline">must</span> choose a new plan or
            Medicare will disenroll you from your current plan and place you
            back in Original Medicare. If you move to an place that is still
            within your plan's service area but there are additional plan
            options available in the new area, you have the{" "}
            <span className="underline">opportunity</span> to choose a new plan
            even outside of the normal open enrollment time periods.
          </p>
          <h3 className="text-xl text-slate-600 font-semibold leading-8 tracking-tight text-center">
            Can I still get benefits from my old plan at my new location?
          </h3>
          <p>
            As we mentioned previously, if you have moved out of the plan's
            service area the plan will disenroll you after a period of time and
            you will revert back to Original Medicare. Until you have been
            officially disenrolled you may use the plan but the same network
            management practices apply at your new location. So there may not be
            any doctors, hospitals or pharmacies that are accessible to you.
            Regardless of where you are in the United States you will still be
            able to access emergency care.{" "}
          </p>
          <p>
            Medicare Advantage plans can be vastly different depending on the
            plan location. A plan with a similar (or even the same) name in a
            different location can have a completely different benefit structure
            and different networks than what you have used before. You should
            talk to a knowledgeable, independent agent to find out what plans
            are available in your area.{" "}
          </p>
          <div className="mt-5 flex justify-center">
            <Link
              to="/gethelp"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow text-white bg-indigo-600 hover:ring hover:bg-indigo-700"
            >
              Get Medicare Advantage Help
            </Link>
          </div>
        </div>

        {/* Medicare New Mover Checklist */}
        <div className="mt-10 mx-20 px-8 py-4 bg-white border border-transparent shadow-lg rounded-md">
          <h2 className="p-2 text-2xl text-slate-800 font-bold leading-8 tracking-tight text-center">
            Moving Checklist for Medicare
          </h2>
          <div className="">
            <ul className="flex flex-col gap-y-3">
              <li className="inline-flex items-center text-gray-700">
                <IoMdCheckboxOutline className="text-orange-700 mr-2 h-5 w-5 shrink-0" />{" "}
                Change your address with Social Security or Railroad Retirement
                Board
              </li>
              <li className="inline-flex items-center text-gray-700">
                <IoMdCheckboxOutline className="text-orange-700 mr-2 h-5 w-5 shrink-0" />{" "}
                Make a list of the current Doctors you see and Prescription
                Drugs you take
              </li>
              <li className="inline-flex items-center text-gray-700">
                <IoMdCheckboxOutline className="text-orange-700 mr-2 h-5 w-5 shrink-0" />{" "}
                Ask Friends and Neighbors around your new house for
                recommendations on Primary Care, Specialists, Hospitals,
                Dentists and Pharmacies
              </li>
              <li className="inline-flex items-center text-gray-700">
                <IoMdCheckboxOutline className="text-orange-700 mr-2 h-5 w-5 shrink-0" />{" "}
                Make a list of what you liked and disliked about your previous
                plan
              </li>
              <li className="inline-flex items-center text-gray-700">
                <IoMdCheckboxOutline className="text-orange-700 mr-2 h-5 w-5 shrink-0" />{" "}
                Check which plans cover your Prescription Drugs and the
                Providers on your list. Your Medicare Agent can do this for you!
              </li>
              <li className="inline-flex items-center text-gray-700">
                <IoMdCheckboxOutline className="text-orange-700 mr-2 h-5 w-5 shrink-0" />{" "}
                Contact a licensed, independent Medicare Agent to assist you in
                choosing a plan
              </li>
            </ul>
          </div>
        </div>

        {/* Lead Form CTA */}
        <div className="px-20 mt-16 text-center space-y-4">
          <h2 className="p-2 text-3xl text-slate-800 font-bold leading-8 tracking-tight text-center">
            We can't help you unpack but we can help you choose a new plan
          </h2>
          <p className="text-indigo-600 text-xl font-semibold">
            Fill out the form below or give us a call at{" "}
            <span className="font-bold text-slate-700">(844)565-1957</span> and
            we'll gladly get you set up with a plan you can use at your new
            home.
          </p>
        </div>
        <Contactform />
      </div>
    </main>
  </Layout>
)

export default NewMoverPage
