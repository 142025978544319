// Derived from https://github.com/LabZoneSK/labzone-gatsby/blob/dev/src/components/contactForm.js

// import React, { useState } from "react"
import React from "react"
// import { Link } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import toast, { Toaster } from "react-hot-toast"

import { formv3 } from "./hubspot"
// import { getIP } from "./getip"

// const { ipAddress, country, coloServer, browserType } = getIP()
// console.log("Contact.js import browserinfo variables")
// console.log(ipAddress, country, coloServer, browserType)
// const toastNotification =
//   "Thank you for contacting us, we will be in touch shortly"

// const notify = () =>
//   toast.success(toastNotification, {
//     duration: 10000,
//     position: "top-center",
//     // className: "",
//     // icon: "",
//     // iconTheme: {
//     //   primary: "#ccc",
//     //   secondary: "#fff",
//     // },
//     ariaProps: {
//       role: "status",
//       "aria-live": "polite",
//     },
//   })

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/

const SignUpSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(1, "Must be longer than 1 characters")
    .max(40, "Must be shorter than 40 characters")
    .required("Required"),
  lastname: Yup.string()
    .min(2, "Must be longer than 2 characters")
    .max(40, "Must be shorter than 40 characters")
    .required("Required"),
  address: Yup.string()
    .min(2, "Must be longer than 2 characters")
    .max(20, "Must be shorter than 20 characters"),
  city: Yup.string()
    .min(2, "Must be longer than 2 characters")
    .max(50, "Must be shorter than 50 characters"),
  state: Yup.string()
    .min(2, "Enter the 2 digit state abbreviation")
    .max(2, "Enter the 2 digit state abbreviation"),
  zip: Yup.string()
    .matches(zipRegExp, "Zip code is not valid")
    .min(5, "Must be 5 characters")
    .max(10, "Must be less than 10 characters"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Must be 10 characters or longer")
    .max(15, "Must be shorter than 15 characters")
    .required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
})

export default function Contactform() {
  return (
    <>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          email: "",
          message: "",
        }}
        validationSchema={SignUpSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const fields = Object.keys(values).map(key => {
            return {
              name: key,
              value: values[key],
            }
          })
          console.log("OnSubmit initial fields value:")
          console.log(fields)

          var hurl =
            "https://api.hsforms.com/submissions/v3/integration/submit/21206617/af5da837-55b6-48fb-aa11-1bc59be9ad6f"

          //   const fields = fieldsInput.map(obj => {
          //     if (obj.name === "message") {
          //       let inputMessage = undefined
          //       inputMessage = `${obj.value} \n ${country} \n ${coloServer} \n ${browserType}`
          //       obj.value = inputMessage
          //       console.log(obj.name, obj.value)
          //     }
          //     return obj
          //   })
          //   console.log(`fields after MAP of browser data:`)
          //   console.log(fields)

          //   console.log("check onSubmit ipAddress: ")
          //   console.log(ipAddress)

          //   formv3(fields, ipAddress)
          formv3(fields, hurl)
          resetForm()
          setSubmitting(false)
          // .then(() => {
          //   setSubmitted(true)
          //   alert("Thank you. We will be in touch shortly.")
          //   resetForm()
          //   //   actions.resetForm()
          // })
          // .catch(() => {
          //   alert(
          //     "There was an error. Please email us at info@micklesenior.com or call 844-565-1957"
          //   )
          // })
          // .finally(() => setSubmitting(false))
          //   setSubmitted(true)
          //   setSubmitting(false)
        }}
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Form className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full">
              <div className="rounded-md shadow-md -space-y-px p-5 border bg-gray-200">
                <div>
                  <div className="flex">
                    <div className="w-1/2 mr-1">
                      <label htmlFor="firstname" className="sr-only">
                        First Name:{" "}
                      </label>
                      <Field
                        name="firstname"
                        placeholder="First Name (Required)"
                        type="text"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      ></Field>

                      <ErrorMessage
                        name="firstname"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>

                    <div className="w-1/2">
                      <label htmlFor="lastname" className="sr-only">
                        Last Name:{" "}
                      </label>
                      <Field
                        name="lastname"
                        placeholder="Last Name (Required)"
                        type="text"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      <ErrorMessage
                        name="lastname"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <label htmlFor="address" className="sr-only">
                      Street Address:{" "}
                    </label>
                    <Field
                      name="address"
                      placeholder="Street Address"
                      type="address"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500 italic text-sm field-error"
                    />
                  </div>
                  <div className="flex mt-1">
                    <div className="w-1/2 mr-1">
                      <label htmlFor="city" className="sr-only">
                        City:{" "}
                      </label>
                      <Field
                        name="city"
                        placeholder="City"
                        type="text"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>
                    <div className="w-2/12 mr-1">
                      <label htmlFor="state" className="sr-only">
                        State:{" "}
                      </label>
                      <Field
                        name="state"
                        placeholder="State"
                        type="text"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>
                    <div className="w-4/12">
                      <label htmlFor="zip" className="sr-only">
                        Zip Code:{" "}
                      </label>
                      <Field
                        name="zip"
                        placeholder="Zip Code"
                        type="text"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      <ErrorMessage
                        name="zip"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="w-1/2 mr-1">
                      <label htmlFor="phone" className="sr-only">
                        Phone Number:{" "}
                      </label>
                      <Field
                        name="phone"
                        placeholder="Phone Number (Required)"
                        type="tel"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>

                    <div className="w-1/2">
                      <label htmlFor="email" className="sr-only">
                        Email:{" "}
                      </label>
                      <Field
                        name="email"
                        placeholder="Email Address (Required)"
                        type="email"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 italic text-sm field-error"
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <label htmlFor="message" className="sr-only">
                      Message:{" "}
                    </label>
                    <Field
                      as="textarea"
                      name="message"
                      placeholder="Tell us about your current Medicare situation"
                      type="textarea"
                      rows="4"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus-outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-red-500 italic text-sm field-error"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    // onClick={notify}
                    disabled={isSubmitting}
                    className="mt-2 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Send
                  </button>
                  <Toaster />
                </div>{" "}
                <div className="">
                  <p className="text-center mt-3 text-xs text-gray-700">
                    This is a solicitation for insurance, a member of our team
                    will contact you to discuss your Medicare options.
                  </p>
                </div>
              </div>
            </div>
          </Form>

          //     <div className="field">
          //       <label htmlFor="email" className="label">
          //         E-mail
          //         {/* <FormattedMessage id="email" defaultMessage="E-mail" /> */}
          //       </label>
          //       <div className="control">
          //         <Field
          //           id="email"
          //           type="email"
          //           name="email"
          //           //   className={cx({
          //           //     input: true,
          //           //     "is-danger": errors.email,
          //           //   })}
          //           placeholder="Where we can respond to you?"
          //         />
          //       </div>
          //       <ErrorMessage
          //         name="email"
          //         render={msg => <p className="">{msg}</p>}
          //         // render={msg => <p className="help is-danger">{msg}</p>}
          //       />
          //     </div>
        )}
      </Formik>
    </>
  )
}
